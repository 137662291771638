import {
  Dispatch,
  FocusEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, Outlet, useMatch } from "react-router-dom";
import styled from "styled-components";
import filterIcon from "../../assets/svg/filter.svg";
import chevronDown from "assets/svg/chevron-down.svg";
import { toCsv } from "utils/csv";
import { Button } from "components/Button";

type NavItem = {
  link: string;
  linkTo: string;
};

type NavTab = {
  navItems?: NavItem[];
  data?: { createdAt: string }[];
  text?: never;
  file?: string;
  canFilter?: boolean;
  setBySpecific?: Dispatch<SetStateAction<string>>;
  setByCustomStart?: Dispatch<SetStateAction<string>>;
  setByCustomEnd?: Dispatch<SetStateAction<string>>;
  setWillFilter?: Dispatch<SetStateAction<string>>;
};
type NavText = {
  text: string;
  navItems?: never;
  data?: { createdAt: string }[];
  file?: string;
  canFilter?: boolean;
  setBySpecific?: Dispatch<SetStateAction<string>>;
  setByCustomStart?: Dispatch<SetStateAction<string>>;
  setByCustomEnd?: Dispatch<SetStateAction<string>>;
  setWillFilter?: Dispatch<SetStateAction<string>>;
};

type TableHeaderProps = NavTab | NavText;

export const TableTab = ({
  navItems,
  data,
  text,
  file,
  canFilter = true,
  setBySpecific,
  setByCustomStart,
  setByCustomEnd,
  setWillFilter,
}: TableHeaderProps) => {
  const [csvText, setText] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [orderSelected, setOrderSelected] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderType, setOrderType] = useState("");
  const [inputType, setInputType] = useState({
    allTime: false,
    specific: false,
    custom: false,
  });
  const [value, setValue] = useState("");
  const [orderTypeValue, setOrderTypeValue] = useState("");
  const [filter, setFilter] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isTypeSelectOpen, setIsTypeSelectOpen] = useState(false);
  const [isPop, setIsPop] = useState(false);
  const popRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLButtonElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  // const match = useMatch(`/dashboard/finance/:tab/:id`);
  const match = useMatch(`/dashboard/orders-management/all-orders`);

  useEffect(() => {
    if (data?.length! > 0 && data !== undefined && file !== undefined) {
      setText(toCsv(data));
      setFileName(file);
    }
  }, [data, file]);

  useEffect(() => {
    if (setBySpecific) setBySpecific(value);
  }, [value]);

  useEffect(() => {
    if (setByCustomStart) setByCustomStart(startDate);
    if (setByCustomEnd) setByCustomEnd(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (setOrderType) setOrderType(orderTypeValue);
  }, [orderTypeValue]);

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (
        !popRef.current?.contains(e!.target as Node) &&
        !selectRef.current?.contains(e!.target as Node)
      )
        setIsPop(false);
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (
        !selectRef.current?.contains(e!.target as Node) &&
        !isPop &&
        !filterRef.current?.contains(e!.target as Node)
      )
        setOpen(false);
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const onOptionClicked = (value: string) => {
    setSelected(value);
  };

  const onOrderOptionClicked = (value: string) => {
    setOrderSelected(value);
  };

  const onInputBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    const { name } = e.target;
    setInputType({ ...inputType, [name]: false });
  };

  const toggle = (value: boolean) => {
    setIsSelectOpen(value);
  };
  const toggleOrderType = (value: boolean) => {
    setIsTypeSelectOpen(value);
  };

  function runCleanup() {
    setValue("");
    setStartDate("");
    setEndDate("");
    setOrderType("");
  }

  return (
    <>
      <Nav>
        <div className="flex tab">
          {navItems !== undefined && (
            <ul className="flex tab-nav">
              {navItems.map((nav, index) => (
                <li key={index}>
                  <NavLink onClick={() => runCleanup()} to={nav.linkTo}>
                    {nav.link}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
          {text !== undefined && <p className="nav-text">{text}</p>}
          <div className="flex">
            <button
              ref={filterRef}
              onClick={() => setOpen(!open)}
              className={`flex btn btn-filter pointer ${
                canFilter ? "" : "no-display"
              }`}
            >
              <div>
                <img src={filterIcon} alt="filter" />
              </div>
              <span className="fw-600 fs-100">Filter</span>
            </button>

            <a
              className="btn export-btn pointer"
              download={fileName}
              href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                csvText
              )}`}
            >
              <span className="fw-600 fs-100">Export</span>
            </a>
          </div>
        </div>
        {open && (
          <aside ref={selectRef} className="grid">
            {match && (
              <>
                <div
                  className="flex filter-options pointer"
                  onClick={() => toggleOrderType(!isTypeSelectOpen)}
                >
                  <p>{orderSelected || "Order type"}</p>
                  <span className={isTypeSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>
                <div className={`select ${isTypeSelectOpen ? "open" : ""}`}>
                  <ul className={`${isTypeSelectOpen ? "grid" : "no-display"}`}>
                    <p
                      className="pointer all"
                      onClick={() => {
                        setOrderTypeValue("all");
                        onOrderOptionClicked("Order Type");
                      }}
                    >
                      All
                    </p>
                    <p
                      className="pointer all"
                      onClick={() => {
                        setOrderTypeValue("promo");
                        onOrderOptionClicked("Promo");
                      }}
                    >
                      Promo
                    </p>
                    <p
                      className="pointer all"
                      onClick={() => {
                        setOrderTypeValue("normal");
                        onOrderOptionClicked("Normal");
                      }}
                    >
                      Normal
                    </p>
                  </ul>
                </div>
              </>
            )}

            <div
              className="flex filter-options pointer"
              onClick={() => toggle(!isSelectOpen)}
            >
              <p>{selected || "Filter by date"}</p>
              <span className={isSelectOpen ? "rotate" : ""}>
                <img src={chevronDown} alt="" />
              </span>
            </div>
            <div className={`select ${isSelectOpen ? "open" : ""}`}>
              <ul className={`${isSelectOpen ? "grid" : "no-display"}`}>
                <p
                  className="pointer all"
                  onClick={() => {
                    setValue("");
                    setFilter(!filter);
                    setIsPop(false);
                    setOpen(false);
                    onOptionClicked("All");
                    if (setWillFilter) setWillFilter(String(Math.random()));
                  }}
                >
                  All
                </p>
                <input
                  className="pointer"
                  onClick={() => {
                    setValue(new Date().toISOString().split("T")[0]);
                    onOptionClicked("Today");
                  }}
                  type="text"
                  placeholder="Today"
                  name="today"
                  readOnly
                />
                <input
                  className="pointer"
                  onFocus={() => setInputType({ ...inputType, specific: true })}
                  onBlur={(e) => onInputBlur(e)}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onClick={() => {
                    onOptionClicked("Specific date");
                  }}
                  type={inputType.specific ? "date" : "text"}
                  value={inputType.specific ? value : "Specific date"}
                  name="specific"
                />
                <input
                  className="pointer"
                  onClick={() => {
                    onOptionClicked("Custom date");
                    setIsPop((isPop) => !isPop);
                  }}
                  type="text"
                  value="Custom date"
                  name="custom"
                  readOnly
                />
              </ul>
            </div>
            <Button
              className={`${value || orderTypeValue ? "" : "no-display"}`}
              onClick={() => {
                setFilter(!filter);
                setIsPop(false);
                setOpen(false);
                if (setWillFilter) setWillFilter(String(Math.random()));
              }}
            >
              Filter
            </Button>
          </aside>
        )}
        {isPop && (
          <div ref={popRef} className="pop-up">
            <div className="grid">
              <p>Custom date</p>
              <div className="flex">
                <div className="grid">
                  <label htmlFor="startDate">From:</label>
                  <input
                    type="date"
                    id="startDate"
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => {
                      setStartDate("");
                      setStartDate(e.target.value);
                      endDateRef.current?.focus();
                    }}
                  />
                </div>

                <div className="grid">
                  <label htmlFor="endDate">To:</label>
                  <input
                    ref={endDateRef}
                    type="date"
                    id="endDate"
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => {
                      setEndDate("");
                      setValue("");
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              <Button
                className="custom"
                onClick={() => {
                  setFilter(!filter);
                  setIsPop(false);
                  setOpen(false);
                  if (setWillFilter) setWillFilter(String(Math.random()));
                }}
              >
                Filter
              </Button>
            </div>
          </div>
        )}
      </Nav>
      <Outlet
        context={[
          toCsv,
          setText,
          setFileName,
          value,
          startDate,
          endDate,
          orderType,
          filter,
        ]}
      />
    </>
  );
};

const Nav = styled.nav`
  position: relative;

  .rotate {
    transform: translateY(-50%) rotate(0.5turn);
  }
  .tab {
    padding: 1em 4em;
    align-items: center;
    justify-content: space-between;
    background-color: hsla(var(--clr-light));
  }
  .tab-nav {
    --gap: 4rem;
    align-items: center;
  }
  .tab-nav .active {
    color: #022f1a;
  }
  .tab-nav .active::after {
    position: absolute;
    content: "";
    width: 100%;
    border-bottom: 2px solid #022f1a;
    padding-bottom: 4em;
  }
  .tab-nav li {
    transition: all 0.3s ease-in-out;
  }

  .tab-nav a {
    color: hsla(var(--clr-black-tint-200));
    position: relative;
    display: grid;
    align-items: center;
  }
  .tab-nav > * > a:hover::after {
    position: absolute;
    content: "";
    color: #022f1a;
    width: 100%;
    border-bottom: 2px solid #022f1a;
    padding-bottom: 4em;
  }
  .nav-text {
    color: #022f1a;
    font-size: 18px;
  }
  .btn-filter {
    border: 1px solid #022f1a;
  }
  button {
    --gap: 0.3rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.3em 1em;
  }
  .export-btn {
    background-color: #022f1a;
    color: #fff;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  aside {
    background-color: hsla(var(--clr-light));
    position: absolute;
    right: 5%;
    width: max-content;
    height: max-content;
    place-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    padding: 3em 1.5em;
    z-index: 9;
    transition-duration: 500ms;
  }
  .filter-options {
    align-items: center;
    justify-content: space-between;
    border: 1px solid hsla(var(--clr-border));
    border-radius: 2px;
    width: 12em;
    padding: 0.625em;

    span {
      transition: transform 0.4s;
    }
  }
  .select {
    width: 12em;
    background: hsla(var(--clr-light));
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    max-height: 0;
    opacity: 0;

    transition-duration: 500ms;

    input {
      outline: transparent solid 2px;
      border: none;
      width: 100%;
      padding: 0.7em;
    }
    input::placeholder {
      color: hsla(var(--clr-dark));
      opacity: 1;
    }
    input:hover {
      background: hsla(var(--clr-yellow-tint-900));
    }
  }

  .open {
    padding-block: 1em;
    max-height: 100%;
    opacity: 1;
  }

  .select ul {
    --gap: 0;
  }

  .select li {
    padding: 0.7em;
  }

  .select li:hover {
    background: hsla(var(--clr-yellow-tint-900));
  }

  .all {
    padding: 0.7em;
  }
  .pop-up {
    width: max-content;
    height: 30vh;
    margin: auto;
    padding: 0.5em 1em;
    position: absolute;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    inset: 0;
    background: hsla(var(--clr-light));
    z-index: 99;

    input {
      padding: 0.625em;
      border: 1px solid hsla(var(--clr-border));
      border-radius: 2px;
      width: 12em;
    }
    .custom {
      justify-self: end;
    }
  }
`;
