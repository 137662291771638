import { useEffect, useState } from "react";
import { TableTab } from "components";
import WalletIcon from "../../assets/svg/wallet-icon.svg";
import ContentLayout from "../../layouts/ContentLayout";
import styled from "styled-components";
import { formatMoney } from "utils/formatMoney";
import OrderManagementService from "services/OrderManagementService";

const navItems = [
  { link: "All Orders", linkTo: "all-orders" },
  { link: "Pending", linkTo: "pending-orders" },
  { link: "Delivered", linkTo: "delivered-orders" },
  { link: "Failed", linkTo: "failed-orders" },
];

const OrderManagement = () => {
  const [collectionWalletbalance, setBalance] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { data },
        } = await OrderManagementService.collectionWalletBalance();
        setBalance(Number(data.balance));
      } catch (error) {}
    };

    fetchData();
  }, []);
  return (
    <ContentLayout titleText="Order Management" subText="Details of Order">
      <ContentWrapper>
        <div className="chart-container">
          <div className="orders-total flex">
            <div className="section-icon">
              <img src={WalletIcon} alt="icon" />
            </div>
            <div className="summation">
              <h3>Order Collection Amount</h3>
              <p className="amount-intro">
                Total amount for order collection amount
              </p>
              <p className="total-amount">
                {formatMoney(collectionWalletbalance)}
              </p>
            </div>
          </div>
        </div>

        <div className="grid data-container">
          <TableTab navItems={navItems} />
        </div>
      </ContentWrapper>
    </ContentLayout>
  );
};

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  position: relative;

  .chart-container {
    padding: 20px 0;
  }

  .orders-total {
    width: 550px;
    height: 180px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding: 20px 25px;
  }
  .section-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #022f1a;
    display: grid;
    place-items: center;
    margin-top: 10px;

    img {
      object-fit: contain;
    }
  }
  .summation {
    display: flex;
    flex-direction: column;

    .amount-intro {
      margin-top: 32px;
    }
    .total-amount {
      margin-top: auto;
      color: #022f1a;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

export default OrderManagement;
