import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

type LayoutProps = {
  children: React.ReactNode;
  component?: React.ReactNode;
  titleText: string;
  subText?: React.ReactNode;
};

const ContentLayout = ({
  children,
  titleText,
  subText,
  component,
}: LayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let pathArr = pathname.split("/").splice(1);
  let rootPath = pathArr[0];
  let crumbs = pathArr.splice(1);

  const crumbNavigation = (str: string, arr: string[]) => {
    let strPath = arr.join("/");
    const reg = new RegExp(str + ".*");

    const path = strPath.replace(reg, "");
    navigate(`/${rootPath}/${path}${str}`);
  };

  return (
    <ContentWrap>
      <header className="header">
        <div className="left-items">
          <div className="bread-crumbs">
            {crumbs.map((str, ind, arr) => (
              <button
                style={{
                  color: ind === arr.length - 1 ? "#022f1a" : "#8d8b8f",
                }}
                onClick={() => crumbNavigation(str, arr)}
                className="crumb"
                key={ind}
              >
                <span className="crumb-string">{str.replace("%20", "-")}</span>{" "}
                /
              </button>
            ))}
          </div>
          <div className="title">
            <h1>{titleText}</h1>
            {subText}
          </div>
        </div>
        <div className="right-items">{component}</div>
      </header>
      <section className="children-node">{children}</section>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 100vh;

  .header {
    background-color: #fff;
    padding: 10px 30px 5px;
    height: 80px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    .bread-crumbs {
      padding-left: 4px;
      padding-bottom: 4px;
    }
    .crumb {
      font-weight: 500;
      cursor: pointer;
      font-size: 14px;
      background: none;
      border: none;
      padding: 0;

      :not(:first-child) {
        padding-left: 2px;
      }

      :hover .crumb-string {
        text-decoration: underline;
      }
    }

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
    .title {
      padding-bottom: 8px;
    }
  }
  .children-node {
    height: calc(100vh - 80px);
    overflow: scroll;
  }
`;
export default ContentLayout;
