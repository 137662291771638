import { TableTab } from "components";
import ContentLayout from "layouts/ContentLayout";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatMoney } from "utils/formatMoney";
import { Link, useLocation } from "react-router-dom";
import { ItemType } from "./Orders";

const header = [
  { key: 1, item: "Brand" },
  { key: 2, item: "Item NO" },
  { key: 3, item: "Type" },
  { key: 4, item: "Ordered Quantity" },
  { key: 5, item: "Quantity Delivered" },
  { key: 6, item: "Price" },
  { key: 7, item: "Date" },
];

type stateType = {
  item: ItemType;
};

const OrderDetails = () => {
  const location = useLocation();
  const [item, setItem] = useState<ItemType>();
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const { item: data } = location.state as stateType;
    setDate(new Date(data.date));
    setItem(data);
  }, []);

  return (
    <ContentLayout titleText="Order Management" subText="Details of order">
      <DetailsWrap>
        <div className="boxes-wrap">
          <div className="detail-box">
            <div className="inner-box-wrap">
              <div className="box-details-header">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </span>
                <span>Outlet Details</span>
              </div>
              <p className="name">
                <span>Name:</span>
                <span>{item?.customerInfo.outletname}</span>
              </p>
              <p className="id">
                <span>Outlet ID:</span>
                <span>{item?.outletID}</span>
              </p>
              <p className="phone">
                <span>Phone number:</span>
                <span>N/A</span>
              </p>
              <p className="address">
                <span>Address:</span>
                <span>{item?.customerInfo.outletaddress}</span>
              </p>
            </div>
          </div>
          <div className="detail-box">
            <div className="inner-box-wrap">
              <div className="box-details-header">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </span>
                <span>Reps Details</span>
              </div>
              <p className="name">
                <span>Name:</span>
                <span>{item?.customerInfo.repname}</span>
              </p>
              <p className="id">
                <span>Reps ID:</span>
                <span>{item?.customerInfo.edcode}</span>
              </p>
              <p className="phone">
                <span>Phone number:</span>
                <span>{item?.customerInfo.phone}</span>
              </p>
              <p className="address">
                <span>Address:</span>
                <span>N/A</span>
              </p>
            </div>
          </div>
          <div className="detail-box">
            <div className="inner-box-wrap">
              <div className="box-details-header">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect x="1" y="3" width="15" height="13"></rect>
                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                  </svg>
                </span>
                <span>Order Details</span>
              </div>
              <p className="name">
                <span>Transaction ID:</span>
                <span>{item?.token}</span>
              </p>
              <p className="id">
                <span>Status:</span>
                <span>{item?.status}</span>
              </p>
              <p className="phone">
                <span>Quantity:</span>
                <span>{item?.quantity}</span>
              </p>
              <p className="address">
                <span>Date:</span>
                <span>{`${date?.getDate()}/${
                  date?.getMonth && date?.getMonth() + 1
                }/${date?.getFullYear()}  ${date?.getHours()}:${date?.getMinutes()}:${date?.getSeconds()}`}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="data-container">
          <TableTab
            data={[]}
            file="order-details.csv"
            text="Items and orders details"
          />

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {header.map((header) => (
                    <th key={header.key}>{header.item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {item?.products.map((product, index) => {
                  let matchingProduct = [];
                  let deliveredQuantity;

                  if (item?.delivery) {
                    matchingProduct = item?.delivery.items.find(
                      (data: any) => product.productCode === data.code
                    );
                    console.log("//", matchingProduct);

                    deliveredQuantity =
                      matchingProduct == undefined
                        ? "-"
                        : matchingProduct.quantity;
                  } else {
                    deliveredQuantity = "-";
                  }

                  return (
                    <tr key={index}>
                      <td>{product.productName}</td>
                      <td>{product.productCode}</td>
                      <td>{product.tag || "NIL"}</td>
                      <td>{product.quantity}</td>
                      <td>{deliveredQuantity}</td>
                      <td>{formatMoney(product.amount)}</td>
                      <td className="table-date">
                        <p>{`${date?.getDate()}/${
                          date?.getMonth && date?.getMonth() + 1
                        }/${date?.getFullYear()}`}</p>
                        <p>{`${date?.getHours()}:${date?.getMinutes()}:${date?.getSeconds()}`}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="total">
          <p>Total</p>

          <p className="cost">
            <span>Total order cost: </span>
            <span>{item?.price && formatMoney(item?.price)}</span>
          </p>
        </div>
        <div className="history">
          <Link to={`/dashboard/outlet-management/${item?.outletID}/history`}>
            <button className="btn btn-default pointer">
              View outlet history
            </button>
          </Link>
        </div>
      </DetailsWrap>
    </ContentLayout>
  );
};

const DetailsWrap = styled.div`
  font-size: 13px;
  line-height: 1.3;

  .table-container {
    height: 100%;
    min-height: 200px;

    table {
      border-bottom: 1px solid #d9d8da;
    }
  }

  .boxes-wrap {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .detail-box {
    width: 300px;
    height: 200px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    animation: fadeIn ease-in;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opaacity: 1;
      }
    }

    :not(:first-child) {
      margin-left: 16px;
    }
    :nth-child(1) {
      animation-duration: 1s;
    }
    :nth-child(2) {
      animation-duration: 2s;
    }
    :nth-child(3) {
      animation-duration: 2.5s;
    }
  }

  .inner-box-wrap p {
    margin-top: 12px;

    span:first-child {
      font-weight: 600;
    }
    span:last-child {
      margin-left: 6px;
    }
  }

  .box-details-header {
    display: flex;
    place-items: center;
    span:first-child {
      background-color: #022f1a;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
    span:last-child {
      margin-left: 8px;
      font-weight: 600;
    }
  }

  .history {
    text-align: center;
    margin-block: 5em;
    button {
      width: 245px;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    padding: 0 70px 0 20px;

    p {
      font-weight: 600;
      font-size: 18px;
      span:last-child {
        font-weight: 300;
        margin-left: 4px;
      }
    }
    .cost {
      border-bottom: 1px solid #d9d8da;
      padding-block: 1em;
    }
  }
`;

export default OrderDetails;
